import { createRouter, createWebHistory } from 'vue-router'

import Dashboard from '@/views/Dashboard.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "createBudget" */ '@/views/Login.vue'),
  },
  {
    path: '/budget',
    name: 'Budget',
    component: () => import(/* webpackChunkName: "createBudget" */ '@/views/BudgetHome.vue'),
  },
  {
    path: '/budget/new',
    name: 'newBudget',
    component: () => import(/* webpackChunkName: "createBudget" */ '@/views/NewBudget.vue'),
  },
  {
    path: '/budget/:year',
    name: 'budgetView',
    component: () => import(/* webpackChunkName: "budgetView" */ '@/views/BudgetView.vue'),
    props: true
  },
  {
    path: '/expenditure',
    name: 'Expenditure',
    component: () => import(/* webpackChunkName: "expenditureHome" */ '@/views/ExpenditureHome.vue'),
  },
  {
    path: '/expenditure/department/:department',
    name: 'ExpenditureByDepartment',
    component: () => import(/* webpackChunkName: "expendituresByDepartment" */ '@/views/ExpenditureHome.vue'),
  },
  {
    path: '/expenditure/all',
    name: 'allExpenditures',
    component: () => import(/* webpackChunkName: "allExpenditures" */ '@/views/ExpenditureHome.vue'),
  },
  {
    path: '/expenditure/:id',
    name: 'ExpenditureView',
    component: () => import(/* webpackChunkName: "expenditureView" */ '@/views/ExpenditureView.vue'),
    props: true
  },
  {
    path: '/expenditure/new',
    name: 'NewExpenditure',
    component: () => import(/* webpackChunkName: "newExpenditure" */ '@/views/NewExpenditure.vue'),
  },
  {
    path: '/quotations',
    name: 'Quotations',
    component: () => import(/* webpackChunkName: "quotationHome" */ '@/views/QuotationsHome.vue')
  },
  {
    path: '/quotations/new',
    name: 'NewQuotation',
    component: () => import(/* webpackChunkName: "newQuotation" */ '@/views/NewQuotation.vue')
  },
  {
    path: '/quotations/:id',
    name: 'QuotationView',
    component: () => import(/* webpackChunkName: "quotationView" */ '@/views/QuotationView.vue'),
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
