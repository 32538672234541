import axios from 'axios'
import store from '../store'

// Add a request interceptor

axios.interceptors.request.use(
  (config) => {
    const { user } = store.state

    if (user) {
      config.headers.Authorization = `${user.data.token_type} ${user.data.access_token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

function getUrl(url) {
    if (!url) {
        return process.env.VUE_APP_BASE_URL
    }
    return url
}

const Request = {
  postRequest(endpoint, data, url) {
      return axios.post(`${getUrl(url)}/${endpoint}`, data)
  },
  getRequest(endpoint, url) {
    return axios.get(`${getUrl(url)}/${endpoint}`)
  },
  patchRequest(endpoint, data, url) {
    return axios.patch(`${getUrl(url)}/${endpoint}`, data)
  },
  deleteRequest(endpoint, url) {
    return axios.delete(`${getUrl(url)}/${endpoint}`)
  }
}

export default Request
