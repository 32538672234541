<template>
    <div class="w-1/3 mr-12 text-white rounded-md">
        <slot name="card-heading" />
        <hr>
        <div class="pt-5 px-7">
            <slot name="expenditure-amount" />
        </div>
        <div class="py-5 px-7">
            <slot name="expenditure-balance" />
        </div>
    </div>
</template>
