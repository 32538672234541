<template>
  <main class="flex flex-col items-center justify-center mx-32 mt-16 px-10 text-sm">
        <img src="@/assets/images/empty-page-icon.svg" alt="no result found" class="mb-8">
        <img src="@/assets/images/empty-page-icon.svg" alt="no result found">
        <div class="my-8 text-lg">
            <slot name="message" />
        </div>
        <router-link :to="{ name: routeName }" class="py-3.5 px-10 bg-primaryColor text-white rounded mb-6">
            <slot name="button-text" />
        </router-link>
  </main>
</template>

<script>
export default {
    props: {
        routeName: String
    }
}
</script>
