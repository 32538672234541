import { createApp } from 'vue'
import EmptyPage from '@/components/EmptyAPIResultView.vue'
import ErrorToast from '@/components/notificationToasts/ErrorToast.vue'
import SuccessToast from '@/components/notificationToasts/SuccessToast.vue'
import LoadingSpinner from './components/ui/LoadingSpinner.vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/index.css'
import './assets/css/tailwind.css'

const app = createApp(App)

app.component('EmptyPage', EmptyPage)
app.component('ErrorToast', ErrorToast)
app.component('SuccessToast', SuccessToast)
app.component('LoadingSpinner', LoadingSpinner)

app.config.globalProperties.$filters = {
  currencyUSD(value) {
    const formatter = new Intl.NumberFormat()

    return formatter.format(value)
  }
}

app.use(store).use(router)
.mount('#app')
