import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null
  },
  mutations: {
    UPDATE_USER_STATE: (state, data) => {
      state.user = data
    },
    LOGOUT_USER: (state) => {
      state.user = null
    }
  },
  actions: {
    updateUserState({ commit }, data) {
      commit('UPDATE_USER_STATE', data)
    },
    logOutUser({ commit }) {
      commit('LOGOUT_USER')
    }
  },
  // modules: {
  // },
})
